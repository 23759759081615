<template>
  <div class="terms">
    <div class="container">
      <h1 class="title">Terms of Service</h1>
      <div class="content">
        <h3>1. Terms</h3>
        <p>
          By accessing the website at
          <a href="https://www.nutworth.com">nutworth.com</a>, you are agreeing to
          be bound by these terms of service, all applicable laws and
          regulations, and agree that you are responsible for compliance with
          any applicable local laws. If you do not agree with any of these
          terms, you are prohibited from using or accessing this site. The
          materials contained in this website are protected by applicable
          copyright and trademark law.
        </p>
        <h3>2. Use License</h3>
        <ol type="a">
          <li>
            Permission is granted to temporarily download one copy of the
            materials (information or software) on nutworth's website for
            personal, non-commercial transitory viewing only. This is the grant
            of a license, not a transfer of title, and under this license you
            may not:
            <ol type="i">
              <li>modify or copy the materials;</li>
              <li>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial); with the exception of
                the "sharing" features
              </li>
              <li>
                attempt to decompile or reverse engineer any software contained
                on nutworth's website;
              </li>
              <li>
                remove any copyright or other proprietary notations from the
                materials; or
              </li>
              <li>
                transfer the materials to another person or "mirror" the
                materials on any other server.
              </li>
            </ol>
          </li>
          <li>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by nutworth at any time.
            Upon terminating your viewing of these materials or upon the
            termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed
            format.
          </li>
          <li>
            Where a user purchases a lifetime license to nutworth, unless
            terminated as provided for under the terms of this agreement,
            nutworth agrees to provide the user with the service on a "lifetime"
            basis. Lifetime shall be defined as for as long as nutworth
            continues to allow new subscriptions to its net worth tracking
            services. In the event of nutworth ceasing to provide these
            services, users with lifetime licenses purchased less than five
            years prior will be entitled to claim a pro-rata refund of the
            purchase price that they paid as if they had paid for a five year
            license term. Lifetime licenses are per user and are not
            transferrable to any other user.
          </li>
        </ol>
        <h3>3. Disclaimer</h3>
        <ol type="a">
          <li>
            The materials on nutworth's website are provided on an 'as is'
            basis. nutworth makes no warranties, expressed or implied, and
            hereby disclaims and negates all other warranties including, without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights.
          </li>
          <li>
            Further, nutworth does not warrant or make any representations
            concerning the accuracy, likely results, or reliability of the use
            of the materials on its website or otherwise relating to such
            materials or on any sites linked to this site.
          </li>
          <li>
            The material on the Website provides general information only and
            has not been prepared having regards to your objectives, financial
            situation or needs. Before making an investment decision, you need
            to consider whether this information is appropriate to your
            objectives, financial situation or needs. Any potential investor
            should consider the latest disclosure document or PDS in deciding
            whether to acquire, or to continue to hold, an investment in any
            fund. You should seek independent professional legal, financial,
            taxation, and/or other professional advice before making an
            investment decision.
          </li>
        </ol>
        <h3>4. Limitations</h3>
        <p>
          In no event shall nutworth or its suppliers be liable for any damages
          (including, without limitation, damages for loss of data or profit, or
          due to business interruption) arising out of the use or inability to
          use the materials on nutworth's website, even if nutworth or a
          nutworth authorized representative has been notified orally or in
          writing of the possibility of such damage. Because some jurisdictions
          do not allow limitations on implied warranties, or limitations of
          liability for consequential or incidental damages, these limitations
          may not apply to you.
        </p>
        <h3>5. Accuracy of materials</h3>
        <p>
          The materials appearing on nutworth's website could include technical,
          typographical, or photographic errors. nutworth does not warrant that
          any of the materials on its website are accurate, complete or current.
          nutworth may make changes to the materials contained on its website at
          any time without notice. However nutworth does not make any commitment
          to update the materials.
        </p>
        <h3>6. Links</h3>
        <p>
          nutworth has not reviewed all of the sites linked to its website and
          is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement by nutworth of the
          site. Use of any such linked website is at the user's own risk.
        </p>
        <h3>7. Modifications</h3>
        <p>
          nutworth may revise these terms of service for its website at any time
          without notice. By using this website you are agreeing to be bound by
          the then current version of these terms of service.
        </p>
        <h3>8. Governing Law</h3>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of Western Australia and you irrevocably submit to the
          exclusive jurisdiction of the courts in that State or location.
        </p>
      </div>
    </div>
    <about-footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AboutFooter from '@/components/AboutFooter'

export default {
  name: 'TermsView',
  components: {
    AboutFooter
  },
  computed: mapState('app', ['appTitle']),
  metaInfo: {
    title: 'Terms of Service',
    meta: [
      {
        name: 'description',
        content: `nutworth's terms of service.`
      }
    ]
  }
}
</script>

<style lang="sass" scoped>
.terms, .container
  display: flex
  flex-direction: column
  flex-grow: 1
p
  font-size: 1.1rem
  max-width: 800px
  margin-bottom: 1.5rem
a
  font-size: 1.2rem
@media screen and (min-width: 1024px)
  .container
    padding-top: 3rem
    padding-bottom: 3rem
@media screen and (max-width: 1023px)
  .container
    padding: 2rem 2rem
@media screen and (max-width: 768px)
  .container
    padding: 2rem 1rem
p, ol, ul, li
  font-size: 1.1rem
  max-width: 800px
  margin-bottom: 1.5rem
ol, ul
  margin-left: 2.5rem
li
  margin-bottom: 0.5rem
a
  font-size: 1.2rem
h3
  font-weight: bold
  margin-bottom: 0.5rem
</style>
